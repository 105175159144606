a {
	text-decoration: none;
}
img {
	max-width: 100%;
}
.container .title {
	font-size: 40px;
	font-weight: 600;
	margin: 40px 0;
    color: #000;
}
.gridarea__list i{
    color: #5F2DED;
}
.gridarea__heading h3{
   color: #000; 
}
.gridarea__heading h3:hover{
    color: #5F2DED;
    cursor: pointer;
}
.blog-container {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	grid-gap: 30px;
	margin-top: 20px;
}

.blog-post {
	position: relative;
	height: 380px;
    background: #fff;
    padding: 12px;
    border-radius: 6px;
	cursor: pointer;
}

.blog-post .cover-img {
	width: 100%;
	height: 200px;
	object-fit: cover;
	border-radius: 10px;
}

.blog-post .title {
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 10px;
}

.blog-post .description {
	font-size: 16px;
	color: #000;
	margin-bottom: 10px;
}

.blog-post .card-details {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
	position: absolute;
	bottom: 0;
	width: 100%;
	flex-wrap: wrap;
}

.card-details .lh-details {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.card-details .date {
	font-size: 14px;
	color: #000;
}

.blog-post .read-more {
	font-size: 16px;
	font-weight: 600;
	color: #0C0E2B;
	text-decoration: none;
	margin-top: 10px;
}

.blog-post .read-more:hover {
	text-decoration: underline;
}

.blog-post .author-img {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	object-fit: cover;
	margin-right: 10px;
}

ul {
	list-style: none;
}

.pagination {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 50px;
	gap: 20px;
}

.pagination .page-number {
	font-size: 16px;
	font-weight: 600;
	color: #fff;
	background: var(--primaryColor);
	padding: 6px 12px;
	border-radius: 10px;
	cursor: pointer;
	transition: all 0.5s ease;
	text-wrap: wrap;
}

.pagination .page-number:hover {
	color: #fff;
	background: var(--primaryColor);
}

.pagination .active {
	color: #fff;
	background: var(--primaryColor);
}

.pagination .active:hover {
	color: #0C0E2B;
	background: var(--primaryColor);
}